<template>
  <div></div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
};
</script>

<style lang="scss" scoped></style>
